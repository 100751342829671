export default theme => ({
  container: {
    border: '3px solid #003C78',
    borderRadius: '15px',
    backgroundColor: '#FCFCFC',
  },
  dialog: {
    minWidth: '400px',
    textAlign: 'center',
  },
  title: {
    fontWeight: '900',
    fontSize: '32px',
    textAlign: 'left',
    lineHeight: '38px',
    color: '#003C78',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  text: {
    padding: '9.5px 0',
    fontSize: '20px',
    lineHeight: '140%',
    textAlign: 'left',
    color: '#000',
    marginTop: theme.spacing(2),
    margin: '0 auto',
    whiteSpace: 'pre-line',
  },
  buttonContainer: {
    justifyContent: 'center',
    paddingBottom: '15px',
  },
})
